<template>
  <section>
    <land-hero-alts
      v-if="banItems.length"
      :items="banItems"
      :points="classify.points"
      :breadcrumbs="crumbs"
    />
    <land-section
      id="content_detail"
      :space="sectionSpace"
    >
      <v-container
        style="max-width: 900px; padding: 0;"
      >
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
          >
            <div class="d-flex flex-column">
              <v-img
                v-if="cntInfo.cover"
                :src="cntInfo.cover"
                cover
                height="100%"
              />
              <v-img
                v-for="(img, ind) in thumbs"
                :key="ind"
                :src="img"
                cover
                height="100%"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </land-section>
  </section>
</template>

<script>
  import data from '@/data/co.data'

  import mixSchemeBanner from '@/pages/mixins/scheme/mix.scheme.banner'

  export default {
    metaInfo: { title: '解决方案' },
    mixins: [
      mixSchemeBanner
    ],
    data () {
      return {
        navMeta: {},
        cntInfo: {},
        thumbs: [],
        crumbs: [],
        defId: '20001',
        relations: [],
        schemes: [],
      }
    },
    computed: {
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      },
      sectionSpace () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return 0
        } else {
          return 40
        }
      }
    },
    created () {
      const contentId = this.$route.query.contentId || this.defId
      this.getContent(contentId)
      this.configCrumbs()

      this.configBanner()
    },
    methods: {
      getContent (contentId) {
        this.cntInfo = data.scheme.getItem(contentId)
        this.thumbs = this.cntInfo.thumbs || []
        // console.log('this.cntInfo: %o', this.cntInfo)
      },
      configCrumbs () {
        this.crumbs = []

        this.crumbs.push(data.nav.home)
        this.crumbs.push(data.nav.scheme)
        this.crumbs.push({
          ...data.nav.current,
          text: this.cntInfo.header.title
        })
      },
      configBanner () {
        this.banItems = [
          {
            bannerId: '2003',
            cover: this.cntInfo.banner,
            title: '解决方案',
          }
        ]
      },
    },
  }
</script>
